.App {
    text-align: center;
}

.header-title {
    font-size: 3rem !important;
    vertical-align: middle;
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.page-wrap {
    min-height: calc(var(--vh, 1vh) * 100);
    margin-bottom: -5rem;
    margin-top: -5rem;
}

.page-wrap:after, .page-wrap:before {
    content: "";
    display: block;
    z-index: -1;
}

.site-header, .page-wrap:before {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 5rem;
}

.site-footer, .page-wrap:after {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 5rem;
}

.page-with-content-middle {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin-bottom: -5rem;
    margin-top: -5rem;
}

.content-middle {
    justify-content: center;
    align-items: center;
    vertical-align: middle !important;
}

.content-top {
    justify-content: center;
    align-items: center;
    vertical-align: top !important;
}

.login-form {
    display: inline-block;
    margin: auto;
    align-self: center;
}

.match-with-login {
    margin-bottom: 5.3rem;
}

hr {
    border-color: blue !important;
}

.config-section {
    margin-bottom: 1rem;
}

.config-section-title {
    color: blue;
}

.text-small {
    font-size: 0.5rem;
}

.error-text {
    color: red;
}

.green-text {
    color: green;
}

.header-menu {
    width: 100%;
    text-align: left;
}

.user-table {
    width: 100%;
}

.spinner {
    animation: App-logo-spin infinite 2s linear;
    height: 64px;
    width: 64px;
    margin: auto;
    display: block;
}

.spinner-sm {
    animation: App-logo-spin infinite 2s linear;
    height: 1.5rem;
    width: 1.5rem;
    margin: auto;
    display: block;
}

.spinner-xs {
    animation: App-logo-spin infinite 2s linear;
    height: 1rem;
    width: 1rem;
    margin: auto;
    display: block;
}

.checkmark {
    height: 1.25rem;
}

.checkmark-sm {
    height: 0.95rem;
}

.checkbox {
    height: 1rem;
    width: 1rem;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.hover-item {
    cursor: pointer
}

.hover-item:hover {
    opacity: 0.5
}

.link-item {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.link-item:hover {
    color: #0056b3;
    text-decoration: underline;
    cursor: pointer
}

.text-lg {
    font-size: 2rem !important;
}

.text-sm {
    font-size: 0.75rem !important;
}

.text-reg {
    font-size: 1rem !important;
}

.button-icon {
    font-size: 1.2rem !important;
}

.game-header-icon {
    font-size: 2rem !important;
}

.timer-icon-lg {
    font-size: 2rem !important;
}

.timer-icon-sm {
    font-size: 1.5rem !important;
}

.grayBack {
    background-color: darkgray;
    color: white;
}

.active-player {
    background-color: cornsilk;
}

.talked-player {
    background-color: lightblue;
    color: gray;
}

.icon-xl {
    max-width: 10rem;
    vertical-align: middle !important;
    margin: auto;
}

.icon-lg {
    height: 1.5rem;
    vertical-align: middle !important;
}

.icon-reg {
    height: 1.25rem;
    vertical-align: middle !important;
}

.icon-sm {
    height: 1rem;
    margin-bottom: 0.2rem;
    vertical-align: middle !important;
}

.icon-xs {
    height: 0.75rem;
    margin-bottom: 0.2rem;
    vertical-align: middle !important;
}

.icon-sex {
    height: 3rem;
    width: 3rem;
    vertical-align: middle !important;
}

.icon-sex-sm {
    height: 1.9rem;
    width: 1.9rem;
    vertical-align: middle !important;
}

.icon-tm {
    height: 3rem;
    margin: 0.5rem;
    vertical-align: middle !important;
}

.icon-active {
    border: 1px solid black;
    border-radius: 0.5rem;
    background-color: linen;
}

.text-bold {
    font-weight: bold;
}

.login-logo {
    height: 30vh;
    max-width: 100%;
}

.text-container {
    display: inline-block !important;
    height: 1.5rem;
}

.disabled-icon {
    opacity: 0.5;
}

.vote-button {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    background-color: lightskyblue;
    width: 3rem;
    margin: 0.25rem;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.vote-button-sm {
    padding: .25rem .5rem !important;
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
    border-radius: .2rem !important;
}

.vote-button-xs {
    padding: 0.22rem 0.4rem !important;
    width: 1.75rem !important;
    font-size: 0.8rem !important;
    line-height: 1.5 !important;
    border-radius: 0.2rem !important;
    font-family: math !important;
}

.check-button {
    display: inline-block;
    font-weight: 400;
    color: black;
    background-color: lightsalmon;
    width: 3rem;
    margin: 0.25rem;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.role-button {
    display: inline-block;
    background-color: lightskyblue;
    margin: 0.25rem;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
}

.role-button-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .2rem;
}

.role-button:hover {
    opacity: 0.5;
}

.role-button:disabled {
    opacity: 0.5;
    background-color: lightgrey;
}

.red-selected {
    background-color: lightsalmon;
}

.black-selected {
    background-color: gray;
}

.roles-selector {
    width: 12.2rem;
    padding: 0rem !important;
}

.vote-button:hover {
    background-color: red;
    color: white;
}

.vote-button:disabled {
    background-color: lightgray;
}

.check-button:hover {
    cursor: help;
}

.vote-selected {
    background-color: red;
    color: white;
}

.nomination-text {
    width: 6.5rem;
}

.big-timer {
    width: 6rem;
    font-family: math;
}

.small-timer {
    width: 2.7rem;
    font-family: math;
}

.number-container {
    display: inline-block !important;
    width: 1.3rem;
}

.remarks-container {
    display: inline-block !important;
    width: 1.4rem;
}

.add-margin-0 {
    margin-bottom: 356px !important;
}

.add-margin-1 {
    margin-bottom: 322px !important;
}

.add-margin-2 {
    margin-bottom: 288px !important;
}

.add-margin-3 {
    margin-bottom: 254px !important;
}

.add-margin-4 {
    margin-bottom: 220px !important;
}

.add-margin-5 {
    margin-bottom: 186px !important;
}

.add-margin-6 {
    margin-bottom: 152px !important;
}

.add-margin-7 {
    margin-bottom: 118px !important;
}

.add-margin-8 {
    margin-bottom: 84px !important;
}

.add-margin-9 {
    margin-bottom: 50px !important;
}

.add-margin-10 {
    margin-bottom: 16px !important;
}

.content-left {
    text-align: left !important;
}

.content-right {
    text-align: right !important;
}

.tournament-list {
    max-width: 40rem;
}

.fouls-container {
    padding-top: 0.4rem;
    padding-bottom: 0.25rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    margin: 0.25rem;
    border: black 1px solid;
    border-radius: 0.5rem;
    display: inline-block;
}

.fouls-container-dead {
    background-color: #ebebeb;
}

.inner-fouls-container {
    width: 3.2rem;
    display: inline-block;
}

.pagination-placeholder {
    height: 2.9rem;
}

.error-placeholder {
    display: inline-block !important;
    height: 1rem;
}

.players-table-row {
    height: 34px !important;
}

.players-row:hover {
    background-color: #f9f9f9;
}

.append-width-account {
    width: 6.7rem;
}

.append-width-login {
    width: 5.8rem;
}

.add-score-input {
    font-size: 0.75rem !important;
    width: 2.7rem !important;
    margin: auto !important;
}

.stream-text {
    color: red;
}

.custom-thead {
    background-color: lightskyblue !important;
    color: white;
}

.music-align {
    vertical-align: text-top;
}

.show-content {
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.hide-content {
    display: none;
}

.collapsible {
    cursor: pointer;
    font-weight: bold;
}

.collapsible:after {
    content: '\25BC';
    /* Unicode character for "plus" sign (+) */
    color: black;
    margin-left: 1rem;
}

.activated:after {
    content: "\25B2";
    /* Unicode character for "minus" sign (-) */
}

.miss-button {
    width: auto !important;
}

.custom-tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.custom-tooltip .custom-tooltiptext {
    opacity: 0;
    font-size: 14px !important;
    transition: opacity 1s;
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    width: 90px;
    bottom: 120%;
    left: 50%;
    margin-left: -45px;
}

.text-red {
    color: red;
}

/* Show the tooltip text when you mouse over the tooltip container */
.custom-tooltip:hover .custom-tooltiptext {
    visibility: visible;
    opacity: 1 !important;
}

.custom-tooltip .custom-tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.youtube-player {
    vertical-align: bottom;
}

.music-vol-slider {
    width: 90%;
}

.big-checkbox {
    -ms-transform: scale(2);
    /* IE */
    -moz-transform: scale(2);
    /* FF */
    -webkit-transform: scale(2);
    /* Safari and Chrome */
    -o-transform: scale(2);
    /* Opera */
    transform: scale(2);
    padding: 10px;
}

.page-elipsis {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    background-color: #fff;
    border: 1px solid #dee2e6;
    user-select: none
}

.current-link {
    background-color: #e9ecef !important;
}

.config-player {
    display: inline-block;
    width: 85%;
    height: 35px;
}

.config-player-radio {
    vertical-align: top;
    margin-top: 11px;
}

.day-back-button {
    height: min-content;
}

.nav-button {
    align-self: center;
}

.nickname-width-10 {
    width: 10rem;
}

.nickname-width-15 {
    width: 15rem;
}

.nickname-width-20 {
    width: 20rem;
}

.nickname-width-30 {
    width: 30rem;
}

.nickname-width-40 {
    width: 40rem;
}

.nickname-width-45 {
    width: 45rem;
}

.vote-name {
    width: 15rem;
}

.vote-buttons {
    width: 20rem;
}

.vote-buttons-xs {
    width: 13.5rem;
}

.two-fouls-rows {
    width: 30rem;
}

.three-fouls-rows {
    width: 21rem;
}

.custom-badge-sm {
    display: inline-block !important;
    width: 1.48rem;
}

.custom-badge-lg {
    display: inline-block !important;
    width: 3rem;
}

.header-button-title {
    margin-left: 0.3rem;
}

.right-most-container {
    width: 5.5rem;
}

.button-with-tooltip:disabled {
    opacity: 1 !important;
    color: gray !important;
}